// TODO: get all extensions from backend
export const EXCEL_TYPE = ['xls', 'xlsx', 'csv', 'xlsm'];
export const DOC_TYPE = ['doc', 'docx', 'rtf', 'txt', 'htm', 'html', 'sfdt'];
export const DRAWING_TYPE = ['dwg', 'ifc', 'rvt', 'rfa', 'ifczip', 'step', 'dwf', 'dwfx', 'stp', 'iges', 'fbx', 'prt', 'pkg', 'nwd', 'pts', 'sldasm', 'sldprt', 'ipt', 'iam'];
export const IMAGE_TYPE = ['jpg', 'png'];
export const PDF_TYPE = ['pdf'];
export const PPT_TYPE = ['pptx'];
export const containerFileTypes = ['IFC', 'RVT', 'RFA', 'NWD', 'DWG', 'OBJ', 'PPTX', 'PDF', 'DOC', 'DOCX', 'RTF', 'TXT', 'XLS', 'XLSX', 'XLSM', 'CSV', 'JPG', 'JPEG', 'PNG'];

export const BIM_ENGINE = 'BIM';
export const CAD_ENGINE = 'CAD';
export const PDF_ENGINE = 'PDF';
export const SHEET_ENGINE = 'Spreadsheet';
export const IMAGE_ENGINE = 'Image';
export const DOCUMENT_ENGINE = 'Document';
export const PANORAMA_ENGINE = 'Panorama';
export const VIDEO_ENGINE = 'Video';
export const engines = [
  BIM_ENGINE,
  CAD_ENGINE,
  PDF_ENGINE,
  SHEET_ENGINE,
  IMAGE_ENGINE,
  DOCUMENT_ENGINE,
  PANORAMA_ENGINE,
  VIDEO_ENGINE,
];

export const workflowViewType = {
  NORMAL_WORKFLOW: 'active',
  ARCHIVED_WORKFLOW: 'archived',
};

export const SUPPORT_PAGE_URL = 'https://support.cube.ms/portal/2?createRequest=true&portalId=2&requestTypeId=9';
export const CONVERSION_STATUS_MAPPER = {
  NOT_STARTED: 'Not started',
  NOT_REQUIRED: '',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'Completed',
  ERRORED: 'Error',
  RETRY: 'In progress',
};
export const TOAST_TYPE_SUCCESS = 'SUCCESS';
export const TOAST_TYPE_NORMAL = 'NORMAL';
export const TOAST_TYPE_ERROR = 'ERROR';
export const TOAST_TYPE_WARNING = 'WARNING';
export const DELHI_CORDINATES = { lat: 28.644800, lng: 77.216721 };

export const TWO_FA_MODE_EMAIL = 'email';
export const TWO_FA_MODE_QR = 'qr';

// Need to migrate to db
export const classificationData = [
  { id: '1a', name: 'Residential', hasChild: true },
  { id: '2a', name: 'Non-Residential ', hasChild: true },
  {
    id: '3a', pid: '1a', name: 'Quarters', hasChild: true,
  },
  {
    id: '4a', pid: '1a', name: 'Dormitories', hasChild: true,
  },
  {
    id: '6a', pid: '1a', name: 'Hostels', hasChild: true,
  },
  {
    id: '7a', pid: '1a', name: 'Guest House', hasChild: true,
  },
  {
    id: '8a', pid: '1a', name: 'Hotels', hasChild: true,
  },
  {
    id: '9a', pid: '2a', name: 'Education buildings', hasChild: true,
  },
  {
    id: '10a', pid: '2a', name: 'Institutional Buildings', hasChild: true,
  },
  {
    id: '11a', pid: '2a', name: 'Assembly Buildings', hasChild: true,
  },
  {
    id: '12a', pid: '2a', name: 'Business Buildings', hasChild: true,
  },
  {
    id: '13a', pid: '2a', name: 'Mercantile buildings', hasChild: true,
  },
  {
    id: '14a', pid: '2a', name: 'Industrial  Buildings', hasChild: true,
  },
  {
    id: '15a', pid: '2a', name: 'Storage Buildings', hasChild: true,
  },
  {
    id: '16a', pid: '2a', name: 'Hazardous Buildings', hasChild: true,
  },
  {
    id: '17a', pid: '2a', name: 'Public Infrastructure', hasChild: true,
  },
  {
    id: '18a', pid: '2a', name: 'Border Works', hasChild: true,
  },
  {
    id: '19a', pid: '2a', name: 'Others', hasChild: true,
  },
  { id: '20a', pid: '3a', name: 'Type I' },
  { id: '21a', pid: '3a', name: 'Type II' },
  { id: '22a', pid: '3a', name: 'Type III' },
  { id: '23a', pid: '3a', name: 'Type IV' },
  { id: '24a', pid: '3a', name: 'Type IV (SPL)' },
  { id: '25a', pid: '3a', name: 'Type V-A (D-II)' },
  { id: '26a', pid: '3a', name: 'Type V-B (D-I)' },
  { id: '27a', pid: '3a', name: 'Type VI-A (C-II)' },
  { id: '28a', pid: '3a', name: 'Type VI (C-I)' },
  { id: '29a', pid: '3a', name: 'Type VII' },
  { id: '30a', pid: '3a', name: 'Type VIII' },
  { id: '31a', pid: '9a', name: 'Schools' },
  { id: '32a', pid: '9a', name: 'Colleges' },
  { id: '33a', pid: '9a', name: 'Training Institute' },
  { id: '34a', pid: '10a', name: 'Hospitals' },
  { id: '35a', pid: '10a', name: 'Nursing Homes' },
  { id: '36a', pid: '10a', name: 'Sanatoria' },
  { id: '37a', pid: '10a', name: 'Custodial Institutions' },
  { id: '38a', pid: '10a', name: 'Penal & Mental institutions' },
  { id: '39a', pid: '12a', name: 'Offices' },
  { id: '40a', pid: '12a', name: 'Banks' },
  { id: '41a', pid: '12a', name: 'Police Station' },
  { id: '42a', pid: '12a', name: 'Laboratories' },
  { id: '43a', pid: '12a', name: 'Outpatient Clinics' },
  { id: '44a', pid: '12a', name: 'Research Establishment' },
  { id: '45a', pid: '12a', name: 'Libraries' },
  { id: '46a', pid: '12a', name: 'Test House' },
  { id: '47a', pid: '12a', name: 'Electronic Data Processing' },
  { id: '48a', pid: '12a', name: 'Information Technology Park' },
  { id: '49a', pid: '12a', name: 'Call Centers' },
  { id: '50a', pid: '12a', name: 'Telephone Exchange' },
  { id: '51a', pid: '12a', name: 'Broad casting station.' },
  { id: '52a', pid: '12a', name: 'TV Station' },
  { id: '53a', pid: '12a', name: 'Air traffic Control tower' },
  { id: '54a', pid: '13a', name: 'Shops' },
  { id: '55a', pid: '13a', name: 'Stores' },
  { id: '56a', pid: '13a', name: 'Underground Shopping Complex' },
  { id: '57a', pid: '13a', name: 'Markets' },
  { id: '58a', pid: '14a', name: 'Assembly plants' },
  { id: '59a', pid: '14a', name: 'Industrial Laboratories' },
  { id: '60a', pid: '14a', name: 'Dry Cleaning Plants' },
  { id: '61a', pid: '14a', name: 'Power Plants' },
  { id: '62a', pid: '14a', name: 'Generating Units' },
  { id: '63a', pid: '14a', name: 'Pumping  Station' },
  { id: '64a', pid: '14a', name: 'Fumigation Chambers' },
  { id: '65a', pid: '14a', name: 'Laundries' },
  { id: '66a', pid: '14a', name: 'Gas plants' },
  { id: '67a', pid: '14a', name: 'Refineries' },
  { id: '68a', pid: '14a', name: 'Dairies' },
  { id: '69a', pid: '14a', name: 'Saw Mills' },
  { id: '70a', pid: '15a', name: 'Ware houses' },
  { id: '71a', pid: '15a', name: 'Cold storage' },
  { id: '72a', pid: '15a', name: 'Freight depots' },
  { id: '73a', pid: '15a', name: 'Transit sheds' },
  { id: '74a', pid: '15a', name: 'Storehouse' },
  { id: '75a', pid: '15a', name: 'Truck & Marine terminals' },
  { id: '76a', pid: '15a', name: 'Garages' },
  { id: '77a', pid: '15a', name: 'Hangers' },
  { id: '78a', pid: '15a', name: 'Grain elevators' },
  { id: '79a', pid: '15a', name: 'Barns' },
  { id: '80a', pid: '15a', name: 'Stables' },
  { id: '81a', pid: '17a', name: 'Road' },
  { id: '82a', pid: '17a', name: 'Bridge' },
  { id: '83a', pid: '17a', name: 'Flyover' },
  { id: '84a', pid: '17a', name: 'Tower' },
  { id: '85a', pid: '17a', name: 'Treatment Plants' },
  { id: '86a', pid: '17a', name: 'Substations' },
  { id: '87a', pid: '17a', name: 'Other Works' },
  { id: '88a', pid: '18a', name: 'Fencing' },
  { id: '89a', pid: '18a', name: 'Lighting' },
  { id: '90a', pid: '18a', name: 'Barracks' },
  { id: '91a', pid: '18a', name: 'Other Work on Border' },
];

export const PLATFORM = {
  web: 'WEB',
  mobile: 'MOBILE',
  desktop: 'DESKTOP',
};

import IconComponent from '../../cubeComponent/icons';
import askAQuestion from '../../assets/icons/AskAQuestion.svg';
import bugReport from '../../assets/icons/BugReport.svg';
import suggestAFeature from '../../assets/icons/SuggestAFeature.svg';
import { ReactComponent as organisationIcon } from '../../assets/icons/organisationSVG.svg';
import ButtonComponent from '../../cubeComponent/form/ButtonComponent';
import knowledgeBase from '../../assets/icons/KnowledgeBase.svg';
import getHomePageUrl from '../../services/hooks/getHomePageUrl';
import { useSelector } from 'react-redux';
import axiosWrapper from '../../services/axiosWrapper';
import css from './index.module.css';
import AvatarComponent from '../../cubeComponent/html/avatar';
import { useContext, useEffect, useState } from 'react';
import { getUrlForImage } from '../../cubeComponent/constants/globalFunctions';
import { ThemeContext } from '../../App';
import { THEME_DARK, THEME_LIGHT } from '../../App.constant';
import { ReactComponent as usersIcon } from '../../assets/icons/svg/healthicons_ui-user-profile.svg';
import { ReactComponent as portalIcon } from '../../assets/icons/svg/jam_computer-alt.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/SettingsIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/svg/edit.svg';
import Tool from '../HoopsViewer/ToolbarViewer/Tool';
import { saveUserDetails } from '../../actions/userDetails';
import { SUPPORT_PAGE_URL } from '../../services/constants';
import { useNavigate } from 'react-router-dom';
import { CiChat1 } from 'react-icons/ci';
import { updateSupportChatProperties } from '../../services/utils/freshChatHelper';
import useResetAuth from '../../services/hooks/resetAuth';
import DropDownListComponent from '@CubeComponents/form/DropdownListComponent';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ProfileSideBar = ({ handleClose }) => {
  const { i18n } = useTranslation();

  const resetAuth = useResetAuth();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const layout = useSelector((state) => state.layout);
  const portalData = useSelector((state) => state.portalData);
  const userPermissions = useSelector((state) => state.userPermissions);

  const [homePageUrl] = getHomePageUrl();
  const [showSettings, setShowSettings] = useState(true);
  const [userInitials, setUserInitials] = useState('');
  const themeContext = useContext(ThemeContext);
  const [userPortalList, setUserPortalList] = useState([]);

  const fetchUserPortalList = async () => {
    const portalData = await axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/accounts/getUserPortalList`);
    setUserPortalList(portalData.data.data);
  };

  useEffect(() => {
    if (layout.PORTAL_SETTINGS?.ENABLE === false) {
      setShowSettings(false);
    } else if (
      typeof layout.PORTAL_SETTINGS?.ENABLE === 'object'
      && layout.PORTAL_SETTINGS?.ENABLE?.CONDITION?.COMPANY_NAME
    ) {
      setShowSettings(
        userDetails?.company?.name
        == layout.PORTAL_SETTINGS?.ENABLE?.CONDITION?.COMPANY_NAME,
      );
    }

    let firstName = userDetails?.firstName?.[0]?.toUpperCase();
    const lastName = userDetails?.lastName?.[0]?.toUpperCase();
    if (lastName) {
      firstName += lastName;
    }
    setUserInitials(firstName);
    fetchUserPortalList();
  }, []);

  useEffect(() => {
    const permissions = _.get(userPermissions, 'RESOURCE_TYPE_PORTAL.permissions', []);
    const hasAccess = _.includes(permissions, 'ACCESS_PORTAL_SETTINGS');
    if (showSettings && !hasAccess) {
      setShowSettings(false);
    }
  }, [userPermissions]);

  const getOrgImage = () => {
    let orgImage = process.env.REACT_APP_IMAGES_PUBLIC_URL + layout.PROJECT_LOGO;
    if (userDetails?.company?.logo) {
      orgImage = getUrlForImage({ id: userDetails.company.id }, 'organization');
    }
    return orgImage;
  };

  const getProfile = (type) => {
    let renderer = userInitials;
    if (userDetails?.profilePic) {
      renderer = <img src={userDetails?.profilePicUrl} />;
    }
    return (
      <AvatarComponent size={type} isCircle bgColor="var(--system-colour-2)">
        {renderer}
      </AvatarComponent>
    );
  };

  const handleLinkClick = (e) => {
    if (e.target.id == 'askQuestion') {
      window.open(SUPPORT_PAGE_URL, '_blank');
    }
    if (e.target.id == 'reportBug') {
      window.open(
        'https://support.cube.ms/portal/2?createRequest=true&portalId=2&requestTypeId=25',
        '_blank',
      );
    }
    if (e.target.id == 'suggestFeature') {
      window.open(
        'https://support.cube.ms/portal/2?createRequest=true&portalId=2&requestTypeId=27',
        '_blank',
      );
    }
    if (e.target.id == 'knowledgeBase') {
      window.open('https://support.cube.ms/space/CKB', '_blank');
    }
    // if (e.target.id == 'cubeLink') {
    //   window.open('https://cube.ms', '_blank');
    // }
    // if (e.target.id == 'techtureLink') {
    //   window.open('https://www.techture.global', '_blank');
    // }
  };

  const handleEditProfileClick = () => {
    handleClose();
    navigate('/user-profile/personalInformation');
  };

  const handleCompanySettingsClick = () => {
    if (!showSettings) {
      return;
    }
    handleClose();
    navigate(`/companySettings/${userDetails?.company.id}/details`);
  };

  const openPortalSetting = () => {
    if (!showSettings) {
      return;
    }
    navigate('/portalSettings/users');
    handleClose();
  };

  const logoClick = () => {
    navigate(homePageUrl);
  };

  // const resetAuth = async () => {
  //   localStorage.setItem('isLogedIn', false);

  //   const ssoStrategy = await axiosWrapper.get(
  //     `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getSsoStrategy`,
  //   );
  //   const responseData = await axiosWrapper.get(
  //     `${process.env.REACT_APP_CUBE_BACKEND}/accounts/getLogoutData`,
  //   );
  //   if (ssoStrategy.data.status && ssoStrategy.data.strategy != 'local') {
  //     // This condition is used for SAML logout in case of CPWD
  //     window.location.href = responseData.data.data.redirectUrl;
  //   } else {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const redirectUrl = encodeURIComponent(
  //       `${location.pathname}?${urlParams.toString()}`,
  //     );
  //     navigate(`/?redirectUrl=${redirectUrl}`);
  //   }
  //   dispatch(saveUserDetails({}));
  // };

  const changeTheme = () => {
    if (themeContext.theme == THEME_LIGHT) {
      themeContext.setTheme(THEME_DARK);
    } else if (themeContext.theme == THEME_DARK) {
      themeContext.setTheme(THEME_LIGHT);
    }
  };

  const handleSupportChatClick = () => {
    updateSupportChatProperties(userDetails, portalData);
    handleClose();
  };

  const themeIconColor = () => {
    if (themeContext.theme == THEME_LIGHT) {
      return 'var(--primary-text)';
    }

    return 'var(--surface-white)';
  };

  const handlePortalSwitch = (args) => {
    window.location.href = `${process.env.REACT_APP_ENV == 'development' ? 'http' : 'https'}://${args.value}/dashboard`;
  };

  const changeLanguage = (args) => {
    i18n.changeLanguage(args.value);
  };

  return (
    <>
      <div className={css.profileDropdownBackdrop} onClick={handleClose} />
      <div
        className={
          themeContext.theme == THEME_LIGHT
            ? css.profileDropdown
            : css.profileDropdownDark
        }
      >
        {userPortalList.length > 1 ? (
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">Switch To</span>
            <DropDownListComponent
              fields={{ text: 'name', value: 'frontendUrl' }}
              value={portalData.frontendUrl}
              dataSource={userPortalList}
              onChange={handlePortalSwitch}
            />
          </div>
        ) : null}

        {['staging', 'development'].includes(process.env.REACT_APP_ENV) ? (
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">Language</span>
            <DropDownListComponent
              fields={{ text: 'text', value: 'value' }}
              value="en"
              dataSource={[{ text: 'English', value: 'en' }, { text: 'Hindi', value: 'hi' }]}
              change={changeLanguage}
            />
          </div>
        ) : null}

        <div
          className={
            themeContext.theme == THEME_LIGHT
              ? css.profileDropdownContent
              : css.profileDropdownContentDark
          }
        >
          <div className="flex w-full justify-between items-center" data-testid="profileHeader">
            <span className="flex items-center">
              <Tool
                Svg={usersIcon}
                color={themeIconColor()}
                setGradient={false}
              />
              <span className="text-lg font-bold items-center flex pl-4" data-testid="profileText">
                Profile
              </span>
            </span>
            {false && (
              <span
                style={{
                  opacity: showSettings ? 1 : 0.5,
                  cursor: showSettings ? 'auto' : 'not-allowed',
                }}
              >
                <Tool
                  Svg={SettingsIcon}
                  color={themeIconColor()}
                  setGradient={false}
                />
              </span>
            )}
            <Tool
              Svg={EditIcon}
              color={themeIconColor()}
              setGradient={false}
              cssClass="cursor-pointer"
              onClick={handleEditProfileClick}
            />
          </div>
          <div
            className={css.profileDropdownContentInfo}
            data-testid="profileHeaderContent"
          >
            <div
              className={css.profileDropdownContentDetails}
              data-testid="profileContentLogo"
            >
              <div
                className={css.avatarInitialsWrapper}
                onClick={logoClick}
                data-testid="profileContentLogoIcon"
              >
                {getProfile('LLG')}
              </div>
            </div>
            <div
              className={css.profileDropdownContentDetails}
              data-testid="profileContentDetails"
            >
              <div
                data-testid="profileUserName"
                className={css.contentmainText}
              >
                {`${userDetails?.firstName} ${userDetails?.lastName ? userDetails?.lastName : ''
                  }`}
              </div>
              <div
                data-testid="profileUserDesignation"
                className={css.contentgenralText}
              >
                {userDetails?.designation}
              </div>
              <div
                data-testid="profileUserEmail"
                className={css.contentgenralText}
              >
                {userDetails?.email}
              </div>
            </div>
          </div>
        </div>
        <>
          <div
            className={
              themeContext.theme == THEME_LIGHT
                ? css.profileDropdownContent
                : css.profileDropdownContentDark
            }
          >
            <div className="flex w-full justify-between items-center" data-testid="orgHeader">
              <span style={{ display: 'flex', justifyComtent: 'left' }}>
                <Tool
                  Svg={organisationIcon}
                  color={themeIconColor()}
                  setGradient={false}
                />
                <span className="text-lg font-bold items-center flex pl-4" data-testid="orgText">
                  Org
                </span>
              </span>
              <span
                style={{
                  opacity: showSettings ? 1 : 0.5,
                  cursor: showSettings ? 'auto' : 'not-allowed',
                }}
                onClick={handleCompanySettingsClick}
                data-testid="orgSettingsIcon"
              >
                <Tool
                  Svg={SettingsIcon}
                  color={themeIconColor()}
                  setGradient={false}
                />
              </span>
            </div>
            <div
              className={css.profileDropdownContentInfo}
              data-testid="orgDetailsContentInfo"
            >
              <div
                className={css.profileDropdownContentDetails}
                data-testid="orgLogo"
              >
                <div data-testid="orgLogoIcon">
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={logoClick}
                    className={css.logoSidebarOther}
                    src={getOrgImage()}
                    alt="Logo"
                  />
                </div>
              </div>
              <div
                className={css.profileDropdownContentDetails}
                data-testid="orgDetails"
              >
                <div
                  className={css.contentmainText}
                  data-testid="orgCompanyName"
                >
                  {userDetails.company?.name}
                </div>
                <div
                  className={css.contentgenralText}
                  data-testid="orgCompanyWebsite"
                >
                  {userDetails.company?.website}
                </div>
                {userDetails.company?.roleName && (
                  <div className={css.nameClass} data-testid="orgCompanyRole">
                    <Tool
                      Svg={usersIcon}
                      color={themeIconColor()}
                      setGradient={false}
                      className={css.imgClass}
                    />
                    <div className={css.contentgenralText}>
                      {userDetails.company?.roleName}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              themeContext.theme == THEME_LIGHT
                ? css.profileDropdownContent
                : css.profileDropdownContentDark
            }
          >
            <div className="flex w-full justify-between items-center" data-testid="portalHeader">
              <span style={{ display: 'flex', justifyComtent: 'left' }}>
                <Tool
                  Svg={portalIcon}
                  color={themeIconColor()}
                  setGradient={false}
                />
                <span className="text-lg font-bold items-center flex pl-4" data-testid="portalText">
                  Portal
                </span>
              </span>
              <span
                style={{
                  opacity: showSettings ? 1 : 0.5,
                  cursor: showSettings ? 'auto' : 'not-allowed',
                }}
                data-testid="portalSettingsIcon"
              >
                <Tool
                  Svg={SettingsIcon}
                  color={themeIconColor()}
                  setGradient={false}
                  onClick={openPortalSetting}
                />
              </span>
            </div>
            <div
              className={css.profileDropdownContentInfo}
              data-testid="portalContentDetailsInfo"
            >
              <div
                className={css.profileDropdownContentDetails}
                data-testid="portalLogo"
              >
                <div data-testid="portalLogoIcon">
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={logoClick}
                    className={css.logoSidebarOther}
                    src={layout.ALLOW_PORTAL_LOGO_UPDATE ? portalData.logoUrl : process.env.REACT_APP_IMAGES_PUBLIC_URL + layout.HEADER_LOGO}
                    alt="Logo"
                  />
                </div>
              </div>
              <div
                className={css.profileDropdownContentDetails}
                data-testid="portalDetails"
              >
                <div className={css.contentmainText} data-testid="portalName">
                  {portalData.name}
                </div>
                <div
                  className={css.contentgenralText}
                  data-testid="portalfrontEndURL"
                >
                  {portalData.frontendUrl}
                </div>
                <div className={css.nameClass} data-testid="portalRole">
                  <Tool
                    Svg={usersIcon}
                    color={themeIconColor()}
                    setGradient={false}
                    className={css.imgClass}
                  />
                  <div
                    className={css.contentgenralText}
                    data-testid="portalRoleName"
                  >
                    {userDetails.portalRole?.frontendName}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(['development'].includes(process.env.REACT_APP_ENV) || window.location.host.includes('.cube.ms')) && (
            <div
              className={
                themeContext.theme == THEME_LIGHT
                  ? css.linksSection
                  : css.linksSectionDark
              }
            >
              <div className={css.profileLinks} data-testid="askAQuestion">
                <div>
                  <img src={askAQuestion} data-testid="askAQuestionImg" />
                </div>
                <div
                  id="askQuestion"
                  onClick={handleLinkClick}
                  className={css.redirectLink}
                  data-testid="askAQuestionLink"
                >
                  Ask a Question
                </div>
              </div>
              <div className={css.profileLinks} data-testid="suggestAFeature">
                <div>
                  <img src={suggestAFeature} data-testid="suggestAFeatureImg" />
                </div>
                <div
                  id="suggestFeature"
                  onClick={handleLinkClick}
                  className={css.redirectLink}
                  data-testid="suggestAFeatureLink"
                >
                  Suggest a Feature
                </div>
              </div>
              <div className={css.profileLinks} data-testid="reportABug">
                <div>
                  <img src={bugReport} data-testid="reportABugImg" />
                </div>
                <div
                  id="reportBug"
                  onClick={handleLinkClick}
                  className={css.redirectLink}
                  data-testid="reportABugLink"
                >
                  Report a Bug
                </div>
              </div>
              <div className={css.profileLinks} data-testid="knowledgeBase">
                <div>
                  <img src={knowledgeBase} data-testid="knowledgeBaseImg" />
                </div>
                <div
                  id="knowledgeBase"
                  onClick={handleLinkClick}
                  className={css.redirectLink}
                  data-testid="knowledgeBaseLink"
                >
                  Knowledge Base
                </div>
              </div>
              {process.env.REACT_APP_CHAT_URL && !['mpmrcl.app.cube.ms', 'cube-pmis.mpmrcl.in'].includes(portalData.frontendUrl) && (
                <div className="cursor-pointer flex items-center gap-4" data-testid="supportChatLink">
                  <CiChat1 />
                  <div onClick={handleSupportChatClick}>Chat with us</div>
                </div>
              )}

            </div>
          )}

          <div className={css.signoutSection} data-testid="signOutBtn">
            <div>
              <ButtonComponent width="100px" onClick={resetAuth}>
                Sign Out
              </ButtonComponent>
            </div>
            <div>
              {['qa'].includes(process.env.REACT_APP_ENV) && (
                <IconComponent
                  name={
                    themeContext.theme == THEME_LIGHT
                      ? 'LightTheme_Icon'
                      : 'DarkTheme_Icon'
                  }
                  onClick={changeTheme}
                  cssClass="cursor-pointer"
                />
              )}
            </div>
          </div>
        </>
        <div className={css.remSpace}>
          <div className={css.remSpaceItem}>
            <div className={css.boldClass}>
CUBE v2.2.0.0
</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSideBar;
